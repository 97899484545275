<template>
  <div class="flex flex-col items-center justify-center">
    <span class="font-rubik font-medium text-xs text-icon uppercase mb-2">Cost</span>
    <span class="font-nunito font-bold text-4xl text-default">
      ${{ price.toLocaleString() }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'SummaryTotal',
  props: {
    price: {
      type: Number,
      required: true,
    },
  },
};
</script>
