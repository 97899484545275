<template>
  <span class="font-rubik font-light text-xs" v-html="`${icon} ${name}`"></span>
</template>

<script>
export default {
  name: 'CardCategory',
  props: {
    icon: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
