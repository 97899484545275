const screens = {
  namespaced: true,
  state: {
    current: null,
    total: null,
    data: [
      {
        id: 1,
        bodyClass: 'bg-invert',
        layout: 'layout-default',
        logo: 'logo_welcome',
        button_text: 'Start Quiz',
      },
      {
        id: 2,
        bodyClass: 'bg-layout-bg-default',
        layout: 'layout-real_estate',
        logo: 'logo_real-estate',
        button_text: 'Select Property',
      },
      {
        id: 3,
        bodyClass: 'bg-layout-bg-default',
        layout: 'layout-cars_for_sale',
        logo: 'logo_cars-4-sale',
        button_text: 'Select Vehicle',
      },
      {
        id: 4,
        bodyClass: 'bg-layout-bg-default',
        layout: 'layout-groceries',
        logo: 'logo_groceries',
        button_text: 'Select Groceries',
      },
      {
        id: 5,
        bodyClass: 'bg-layout-bg-default',
        layout: 'layout-grub_club',
        logo: 'logo_grub-club',
        button_text: 'Select Meal',
      },
      {
        id: 6,
        bodyClass: 'bg-layout-bg-default',
        layout: 'layout-night_life',
        logo: 'logo_nightlife',
        button_text: 'Select Nightlife',
      },
      {
        id: 7,
        bodyClass: 'bg-layout-bg-default',
        layout: 'layout-holiday_hub',
        logo: 'logo_holidayhub',
        button_text: 'Select Holiday',
      },
      {
        id: 8,
        bodyClass: 'bg-fashion-male',
        layout: 'layout-default',
        logo: 'logo_fashion',
        button_text: 'Select Outfit',
      },
      {
        id: 9,
        bodyClass: 'bg-invert',
        layout: 'layout-default',
        logo: 'logo_other-costs',
        button_text: 'See Budget',
      },
      {
        id: 10,
        bodyClass: 'bg-layout-bg-default',
        layout: 'layout-default',
        logo: null,
        button_text: null,
      },
    ],
    preScreenData: [
      {
        id: 1,
        forScreen: 8,
        choice: null,
      },
    ],
  },
  mutations: {
    setCurrentScreen(state, value) {
      state.current = value;
    },
    setPreScreenData(state, payload) {
      state.preScreenData = state.preScreenData.map((psd) => (psd.id === payload.id ? payload : psd));
    },
    setTotal(state, value) {
      state.total = value;
    },
  },
  getters: {
    getCurrentScreen: (state) => state.current,
    getCurrentScreenData: (state) => state.data.filter((sd) => sd.id === state.current)[0],
    getPreScreenData: (state) => state.preScreenData.filter((psd) => psd.forScreen === state.current)[0],
    getTotal: (state) => state.total,
  },
};

export default screens;
