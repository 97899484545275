<template>
  <div class="flex flex-col items-start lg:flex-row mt-0 lg:mt-12">
    <div class="my-4 w-full lg:w-5/12 flex flex-col justify-center h-full">
      <h2 class="font-nunito font-semibold text-2xl lg:text-xl text-center mb-12">
        Your ideal lifestyle costs are displayed below.
        Remember you'll need a job that pays between 20% and 37.5% more than
        your annual costs due to tax.
      </h2>

      <SummaryGauge @beforeMount="spenderType = $event.type" />
      <SummaryReportTabs @user-action:updatePriceDisplay="coefficient = $event" />
      <SummaryTotal :price="updatePriceDisplay()" />

      <p class="font-nunito font-normal text-base text-center mt-12 mb-4">
        If you want to lower or increase your cost, change any of your choices
        you can use the edit icon next to each
        grouping and go back to that question.
      </p>
    </div>

    <div class="w-full lg:w-7/12 lg:pl-16">
      <SummaryGraph />

      <div>
        <Accordion
          v-for="category in $store.getters['summary/getCategories']"
          :key="category.id"
          :icon="`icon-${category.name.toLowerCase().replace(' ', '-')}`"
          :text="category.name"
          :price="updatePriceDisplay(category.value)"
          :items="category.items"
          :coefficient="coefficient"
        />
      </div>

      <div class="flex items-center justify-center mt-12 max-w-xs mx-auto text-center bg-black rounded-3xl">
        <a
          href="https://portal.aaafc.com.au/my-account/"
          title="Back to my account"
          class="w-full text-white font-rubik font-normal text-base py-2.5 px-5"
        >
          Back to my account
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from '@/components/Accordion/Accordion.vue';
import SummaryTotal from '@/components/Summary/SummaryTotal.vue';
import SummaryGauge from '@/components/Summary/SummaryGauge.vue';
import SummaryGraph from '@/components/Summary/SummaryGraph.vue';
import SummaryReportTabs from '@/components/Summary/SummaryReportTabs.vue';

const DAYS_IN_A_WEEK = 7;
const DAYS_IN_A_YEAR = 365;
const MONTHS_IN_A_YEAR = 12;
const AVERAGE_WEEKS_IN_MONTH = 4.33;

export default {
  name: 'Summary',
  components: {
    Accordion,
    SummaryGauge,
    SummaryGraph,
    SummaryTotal,
    SummaryReportTabs,
  },
  data() {
    return {
      weeklyPrice: null,
      coefficient: DAYS_IN_A_YEAR,
      spenderType: null,
    };
  },
  computed: {
    dailyPrice() {
      return this.weeklyPrice / DAYS_IN_A_WEEK;
    },
    monthlyPrice() {
      return this.weeklyPrice * AVERAGE_WEEKS_IN_MONTH;
    },
    yearlyPrice() {
      return (this.weeklyPrice / DAYS_IN_A_WEEK) * DAYS_IN_A_YEAR;
    },
  },
  beforeMount() {
    this.weeklyPrice = this.$store.getters['summary/getTotal'];
  },
  mounted() {
    const API_ENDPOINT = 'https://portal.aaafc.com.au/wp-json/budget/v1/update/1';
    const { uid } = this.$store.getters.getUserData;
    const spenderType = encodeURI(this.spenderType);
    const weeklySpend = parseInt(this.weeklyPrice, 10);
    const URL = `${API_ENDPOINT}?uid=${uid}&spenderProfile=${spenderType}&weeklySpend=${weeklySpend}`;

    // Save only if quiz data has been edited
    if (this.$store.getters.getEditState) {
      this.$firebase.firestore()
        .collection('users')
        .doc(this.$store.getters.getUserData.uid)
        .set({
          type: this.spenderType,
          averageWeeklySpend: this.weeklyPrice,
          questionData: this.$store.getters['questions/getQuestionData'],
          categoryData: this.$store.getters['summary/getCategories'],
        })
        .then(() => {
          console.info('Successfully saved the data.');
        })
        .catch((err) => console.error(err));

      fetch(URL, { method: 'GET' }).then((response) => {
        console.info(response);
      });
    }
  },
  methods: {
    updatePriceDisplay(value = null) {
      let price = 0;

      if (this.coefficient === DAYS_IN_A_WEEK) {
        price = value !== null ? (value / DAYS_IN_A_WEEK) : this.dailyPrice;
      }

      if (this.coefficient === AVERAGE_WEEKS_IN_MONTH) {
        price = value !== null ? value : this.weeklyPrice;
      }

      if (this.coefficient === MONTHS_IN_A_YEAR) {
        price = value !== null ? (value * AVERAGE_WEEKS_IN_MONTH) : this.monthlyPrice;
      }

      if (this.coefficient === DAYS_IN_A_YEAR) {
        price = value !== null ? (value / DAYS_IN_A_WEEK) * DAYS_IN_A_YEAR : this.yearlyPrice;
      }

      return parseFloat(price.toFixed(2));
    },
  },
};
</script>
