const questions = {
  namespaced: true,
  state: {
    data: {
      realEstate: {
        name: null,
        data: null,
        screenID: 2,
        choice: null,
        value: null,
        categoryId: null,
      },
      carsForSale: {
        name: null,
        data: null,
        screenID: 3,
        choice: null,
        value: null,
        categoryId: null,
      },
      groceries: {
        name: null,
        data: null,
        screenID: 4,
        choice: null,
        value: null,
        categoryId: null,
      },
      grubClub: {
        name: null,
        data: null,
        screenID: 5,
        choice: null,
        value: null,
        categoryId: null,
      },
      nightLife: {
        name: null,
        data: null,
        screenID: 6,
        choice: null,
        value: null,
        categoryId: null,
      },
      holidayHub: {
        name: null,
        data: null,
        screenID: 7,
        choice: null,
        value: null,
        categoryId: null,
      },
      fashion: {
        name: null,
        data: null,
        screenID: 8,
        choice: null,
        value: null,
        categoryId: null,
      },
    },
  },
  mutations: {
    setQuestionData(state, payload) {
      state.data[payload.question].name = payload.name;
      state.data[payload.question].data = payload.data;
      state.data[payload.question].choice = payload.choice;
      state.data[payload.question].value = payload.value;
      state.data[payload.question].categoryId = payload.categoryId;
    },
  },
  getters: {
    getQuestionData: (state) => state.data,
  },
};

export default questions;
