<template>
  <div class="flex items-center w-fit mx-auto flex-col">
    <div class="relative">
      <img
        src="@/assets/images/gauge.png"
        height="133"
        width="265"
        class="h-auto w-auto"
        alt="gauge"
      >
      <img
        ref="needle"
        src="@/assets/svg/icons/icon-needle.svg"
        class="absolute transform -rotate-90 top-1/6 right-needle origin-bottom"
        :style="`transform: rotate(${-90 + turnDegrees}deg);`"
        alt="needle"
      >
    </div>

    <div class="flex flex-row justify-between w-full font-nunito font-bold">
      <span class="leading-5 -ml-4 text-sm pt-4">Thrifty Saver</span>
      <span class="leading-5 -mr-4 text-sm pt-4">Big Spender</span>
    </div>

    <h2 class="font-nunito font-bold text-xl mt-4">{{ spenderType }}</h2>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

// to-do: decouple this
const WEEKLY_MAX_AMOUNT = 3545;

export default {
  name: 'SummaryGauge',
  data() {
    return {
      turnDegrees: 0,
    };
  },
  beforeMount() {
    this.$emit('beforeMount', { type: this.spenderType });
  },
  mounted() {
    const { needle } = this.$refs;
    let degreeToTurn = Math.floor((this.percentage / WEEKLY_MAX_AMOUNT) * 180);

    degreeToTurn = degreeToTurn > 180 ? 180 : degreeToTurn;
    this.turnDegrees = degreeToTurn;
    needle.animate([
      { transform: 'rotate(-90deg)' },
      { transform: `rotate(${-90 + degreeToTurn}deg)` },
    ],
    {
      easing: 'ease-out',
      duration: 1700,
    });
  },
  computed: {
    ...mapGetters({
      total: 'summary/getTotal',
    }),
    percentage() {
      return (this.total / 100) * 100;
    },
    spenderType() {
      if (this.percentage <= (WEEKLY_MAX_AMOUNT / 3)) {
        return 'Thrifty Saver';
      }

      if (this.percentage <= ((WEEKLY_MAX_AMOUNT / 3) * 2)) {
        return 'In Betweener';
      }

      return 'Big Spender';
    },
  },
};
</script>
