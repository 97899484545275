<template>
  <div class="flex flex-wrap items-center justify-end">
    <span
      class="font-rubik font-light text-xs leading-none text-icon bg-left bg-no-repeat pl-5 mr-4"
      :style="`background-image: url(${require('@/assets/svg/icons/icon-bedroom.svg')})`"
    >
      {{ bedrooms }}
    </span>

    <span
      class="font-rubik font-light text-xs leading-none text-icon bg-left bg-no-repeat pl-5 mr-4"
      :style="`background-image: url(${require('@/assets/svg/icons/icon-bathroom.svg')})`"
    >
      {{ bathrooms }}
    </span>

    <span
      class="font-rubik font-light text-xs leading-none text-icon bg-left bg-no-repeat pl-5"
      :style="`background-image: url(${require('@/assets/svg/icons/icon-carspace.svg')})`"
    >
      {{ carSpaces }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CardRentMeta',
  props: {
    bedrooms: {
      type: Number,
      required: false,
      default: 0,
      validator: (num) => num % 1 === 0,
    },
    bathrooms: {
      type: Number,
      required: false,
      default: 0,
      validator: (num) => num % 1 === 0,
    },
    carSpaces: {
      type: Number,
      required: false,
      default: 0,
      validator: (num) => num % 1 === 0,
    },
  },
};
</script>
