<template>
<!-- eslint-disable-next-line max-len -->
  <div class="inline-flex items-center justify-center bg-white bg-opacity-60 backdrop-filter backdrop-blur-xl rounded-full py-1 px-4">
    <span v-if="icon">
      <img :src="require(`@/assets/svg/${icon.dir}/${icon.filename}`)" alt="">
    </span>
    <span v-if="text" class="font-rubik font-light text-default text-xs pl-1">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'CardInfo',
  props: {
    icon: {
      type: Object,
      required: false,
      validator: (obj) => !!obj.dir && !!obj.filename,
    },
    text: {
      type: String,
      required: false,
    },
  },
};
</script>
