<template>
  <div class="font-nunito font-normal text-xl">
    <span class="font-bold">{{ currentPage }}</span> of {{ totalPages }}
  </div>
</template>

<script>
export default {
  name: 'ToolbarPageIndicator',
  props: {
    currentPage: {
      type: Number,
      required: true,
      default: 1,
    },
    totalPages: {
      type: Number,
      required: true,
      default: 9,
    },
  },
};
</script>
