<template>
  <button
    @click="handleOnClick"
    :class="isToggled ? 'bg-gray-300 bg-opacity-70' : 'bg-white'"
    class="inline-flex items-center relative border border-gray-500 rounded-full p-1.5 transition-colors"
    style="outline: none !important;"
  >
    <span
      :class="isToggled ? 'right-1' : 'left-1'"
      class="block absolute top-1/2 transform -translate-y-1/2 w-5 h-5 rounded-full bg-gray-500 transition-lr"
    ></span>
    <span
      :class="isToggled ? 'pl-2 pr-6' : 'pl-6 pr-2'"
      class="text-nunito text-xs text-gray-500"
    >
      {{ text }}
    </span>
  </button>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: {
    label: {
      type: Object,
      required: false,
      validator: (obj) => !!obj.on && !!obj.off,
    },
  },
  data() {
    return {
      isToggled: false,
    };
  },
  computed: {
    text() {
      if (this.label) {
        return this.isToggled ? this.label.on : this.label.off;
      }

      return this.isToggled ? 'yes' : 'no';
    },
  },
  methods: {
    handleOnClick() {
      this.isToggled = !this.isToggled;
      this.$emit('user-action:toggle', {
        toggled: this.isToggled,
        label: this.label,
      });
    },
  },
};
</script>
