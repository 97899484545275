<template functional>
  <transition name="fade">
    <div class="flex items-center justify-center lg:justify-start mb-12 lg:mb-24 h-18">
      <v-lazy-image
        :key="parent.$store.getters['layout/getActiveLogo']"
        :src="require(`@/assets/svg/logos/${parent.$store.getters['layout/getActiveLogo']}.svg`)"
        height="56"
        width="250"
        alt="logo"
        class="opacity-only h-auto w-auto"
      />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Logo',
  functional: true,
};
</script>
