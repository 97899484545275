<template functional>
  <div class="fixed inset-0 z-100 bg-white flex flex-col items-center justify-center">
    <p class="font-nunito font-bold text-lg">Something went wrong...</p>
    <p class="font-nunito font-bold text-xs">Error: Missing <code>uid</code> query parameter</p>
  </div>
</template>

<script>
export default {
  name: 'Error',
  functional: true,
};
</script>
