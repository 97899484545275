<template>
  <li class="w-full pl-12">
    <div class="flex jusfity-between text-default mb-2 w-full">
      <span class="font-nunito font-normal text-xs w-1/2">
        {{ typeof option.value === 'number' ? updateLabelDisplay(option.name) : option.name }}
      </span>
      <span class="font-nunito font-normal text-xs w-1/2 text-right">
        ${{ typeof option.value === 'number' ? updatePriceDisplay(option.value) : option.value }}
      </span>
    </div>

    <div
      v-for="item in option.data" :key="item.name"
      class="flex jusfity-between text-default mb-2 w-full"
    >
      <span class="font-nunito font-normal text-xs w-1/2">{{ updateLabelDisplay(item.name) }}</span>
      <span class="font-nunito font-normal text-xs w-1/2 text-right">
        {{ typeof item.value === 'number' ? `$${updatePriceDisplay(item.value)}` : item.value }}
      </span>
    </div>
  </li>
</template>

<script>
const DAYS_IN_A_WEEK = 7;
const DAYS_IN_A_YEAR = 365;
const MONTHS_IN_A_YEAR = 12;
const AVERAGE_WEEKS_IN_MONTH = 4.33;

export default {
  props: {
    option: {
      type: Object,
      required: true,
      validator: (obj) => !!obj.name && !!obj.value,
    },
    coefficient: {
      type: Number,
      required: true,
      validator: (num) => num === DAYS_IN_A_WEEK
        || num === DAYS_IN_A_YEAR
        || num === MONTHS_IN_A_YEAR
        || num === AVERAGE_WEEKS_IN_MONTH,
    },
  },
  methods: {
    updatePriceDisplay(value) {
      let price = 0;

      if (this.coefficient === DAYS_IN_A_WEEK) {
        price = value / DAYS_IN_A_WEEK;
      }

      if (this.coefficient === AVERAGE_WEEKS_IN_MONTH) {
        price = value;
      }

      if (this.coefficient === MONTHS_IN_A_YEAR) {
        price = value * AVERAGE_WEEKS_IN_MONTH;
      }

      if (this.coefficient === DAYS_IN_A_YEAR) {
        price = (value / DAYS_IN_A_WEEK) * DAYS_IN_A_YEAR;
      }

      return parseFloat(price.toFixed(2));
    },
    updateLabelDisplay(label) {
      let formattedLabel = null;

      if (this.coefficient === DAYS_IN_A_WEEK) {
        formattedLabel = label.slice().replace('%s', 'Daily');
      }

      if (this.coefficient === AVERAGE_WEEKS_IN_MONTH) {
        formattedLabel = label.slice().replace('%s', 'Weekly');
      }

      if (this.coefficient === MONTHS_IN_A_YEAR) {
        formattedLabel = label.slice().replace('%s', 'Monthly');
      }

      if (this.coefficient === DAYS_IN_A_YEAR) {
        formattedLabel = label.slice().replace('%s', 'Yearly');
      }

      return formattedLabel;
    },
  },
};
</script>
