<template>
  <div>
    <div class="max-w-5xl mx-auto mb-12 lg:mb-16">
      <h2 class="font-nunito font-bold text-center text-4xl lg:text-5xl mb-8">
        {{ heading }}
      </h2>

      <p v-if="description !== ''" class="font-nunito font-medium text-center text-xl lg:text-2xl">{{ description }}</p>
    </div>

    <div class="max-w-2xl lg:max-w-3xl mx-auto bg-white rounded-md px-4 py-12">
      <AdditionalCost
        v-for="additionalCost in $appData.additional_costs"
        :key="additionalCost.id"
        :icon="additionalCost.icon"
        :name="additionalCost.name"
        :description="additionalCost.description"
        :value="additionalCost.value"
        :sid="9"
        :cid="6"
      />
    </div>
  </div>
</template>

<script>
import AdditionalCost from '@/components/AdditionalCost.vue';

export default {
  name: 'AdditionalCostsScreen',
  components: {
    AdditionalCost,
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>
