<template>
  <div class="bg-white shadow-3xl rounded-3xl p-4 mb-10 lg:mb-5">
    <vc-donut
      class="font-nunito font-semibold text-layout-text-default text-sm"
      background="white"
      foreground="grey"
      :size="200"
      unit="px"
      :thickness="35"
      has-legend
      legend-placement="right"
      :sections="sectionData"
      :total="100"
      :start-angle="0"
      :auto-adjust-text-size="true"
    >
    </vc-donut>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SummaryGraph',
  computed: {
    ...mapGetters({
      total: 'summary/getTotal',
      categories: 'summary/getCategories',
    }),
    sectionData() {
      const data = [];

      this.categories.forEach((c) => {
        const val = c.value !== 0 ? (c.value / this.total) * 100 : 0;

        data.push({
          label: `${c.name} ${val.toFixed(1)}%`,
          value: val,
          color: c.color,
        });
      });

      return data;
    },
  },
};
</script>

<style lang="scss">
.cdc {
  margin-right: 40px;

  @media (max-width: 484px) {
    margin-right: 0;
  }

  @media (max-width: 440px) {
    width: 30% !important;
    padding-bottom: 30% !important;
    margin-bottom: 50px;
  }
}

.cdc-legend {
  margin: 0 0 0 40px !important;

  .cdc-legend-item {
    margin: 0 0 2px 0;

    span:nth-last-child(1) {
      font-family: 'Nunito';
      font-size: 13px;
      font-weight: 500;
      opacity: 0.8;

      @media (max-width: 425px) {
        font-size: 16px;
      }
    }

    .cdc-legend-item-color{
      height: 8px;
      width: 8px;
      margin-right: 15px;

      @media (max-width: 425px) {
        height: 12px;
        width: 12px;
      }
    }
  }
}
</style>
