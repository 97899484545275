<template>
  <div class="relative bg-white rounded-3xl px-3 py-8 mb-6">
    <transition name="fade">
      <CardOverlay
        v-show="isSelected"
        class="absolute inset-0 z-30"
      />
    </transition>

    <div v-for="item in data" :key="item.id" class="cursor-pointer" @click="onClick">
      <v-lazy-image
        :src="require(`@/assets/images/fashion/${item.thumbnail.dir}/${item.thumbnail.filename}`)"
        alt=""
        class="w-full h-auto"
      />

      <div class="flex flex-wrap items-start justify-between mb-6 px-2 mt-3">
        <div>
          <span class="block font-nunito font-normal text-default text-lg text-left pr-2">
            {{ item.name }}
          </span>

          <span v-if="item.description" class="block font-rubik font-light text-xs lg:text-sm text-left text-default">
            {{ item.description }}
          </span>
        </div>

        <span v-if="item.price" class="font-rubik font-medium text-default text-lg text-right pl-2">
          ${{ item.price }}
        </span>
      </div>
    </div>

    <div class="flex items-center justify-between border-t border-layout-text-default p-2">
      <span class="font-nunito font-normal text-lg text-default">Total Cost</span>
      <span class="font-nunito font-bold text-lg text-default">${{ total.toLocaleString() }}</span>
    </div>
  </div>
</template>

<script>
import CardOverlay from '@/components/Card/CardOverlay.vue';

export default {
  name: 'CardSimple',
  components: {
    CardOverlay,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
      validator: (array) => array.length > 0,
    },
    cid: {
      type: Number,
      required: true,
      validator: (num) => num % 1 === 0,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    qid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      total: null,
    };
  },
  beforeMount() {
    this.data.forEach((item) => {
      this.total += item.price;
    });
  },
  methods: {
    onClick() {
      const AVERAGE_WEEKS_IN_MONTH = 4.33;
      const MONTHS_IN_A_YEAR = 12;
      const totalWeeklyPrice = (this.total / MONTHS_IN_A_YEAR) / AVERAGE_WEEKS_IN_MONTH;

      const clothingData = this.data.map((item) => {
        const itemWeeklyPrice = (item.price / MONTHS_IN_A_YEAR) / AVERAGE_WEEKS_IN_MONTH;
        return { ...item, ...{ value: itemWeeklyPrice } };
      });

      clothingData.unshift({
        name: 'Amount of purchases in a year (seasons)',
        value: 'x4',
      });

      this.$store.commit('questions/setQuestionData', {
        name: 'Clothing Total',
        question: this.qid,
        choice: this.id,
        value: totalWeeklyPrice,
        data: clothingData,
        categoryId: this.cid,
      });
    },
  },
};
</script>
