<template>
  <div
    @click="isActive = !isActive"
    class="bg-white first:rounded-t-3xl last:rounded-b-3xl px-4 cursor-pointer mb-1"
  >

   <div class="flex justify-between">
      <div class="flex items-center flex-1 py-3">
        <div class="bg-special-light-blue bg-opacity-10 rounded-full h-9 w-9 mr-2">
          <div class="flex justify-center items-center h-full">
            <img
              :src="require(`@/assets/svg/icons/${icon}.svg`)"
              height="24"
              width="24"
              alt=""
              class="h-auto w-auto"
            >
          </div>
        </div>

        <p class="font-nunito font-semibold text-default text-base mr-4">{{ text }}</p>
        <IconEdit @click.native.stop="edit" />
      </div>

      <div class="flex items-center">
        <p class="font-rubik font-medium text-default text-lg mr-1">${{ price.toLocaleString() }}</p>
        <IconCircledArrow
          class="transform transition-transform text-default"
          :class="{'rotate-180': isActive}"
        />
      </div>
    </div>

    <ul
      v-if="items"
      v-show="isActive"
      class="py-4"
    >
      <AccordionItem
        v-for="(item, index) in items"
        :option="item"
        :coefficient="coefficient"
        :key="index"
      />
    </ul>
  </div>
</template>

<script>
import AccordionItem from '@/components/Accordion/AccordionItem.vue';
import IconEdit from '@/components/Icons/IconEdit.vue';
import IconCircledArrow from '@/components/Icons/IconCircledArrow.vue';

export default {
  name: 'Accordion',
  components: {
    AccordionItem,
    IconEdit,
    IconCircledArrow,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    coefficient: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    edit() {
      const screenIds = [];

      this.items.forEach((item) => {
        if (!screenIds.includes(item.screen_id)) {
          screenIds.push(item.screen_id);
        }
      });

      // Ability to add additional costs if none are selected on the first go
      if (screenIds.length === 0) {
        screenIds.push(9);
      }

      this.$store.commit('setEditState', true);
      this.$store.commit('screens/setCurrentScreen', screenIds.shift());
      this.$store.commit('setEditableScreens', screenIds.splice(0, 1));
    },
  },
};
</script>
