<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
  <!-- eslint-disable max-len -->
    <path
      d="M44.207 0.931195C43.361 0.459557 42.4305 0.159624 41.4688 0.0485495C40.507 -0.062525 39.5328 0.017437 38.6019 0.283864C37.671 0.55029 36.8017 0.997956 36.0436 1.60126C35.2855 2.20456 34.6535 2.95166 34.1839 3.79985L20.5016 28.4695L12.671 20.6241C11.9912 19.9189 11.178 19.3564 10.2788 18.9694C9.37963 18.5824 8.41255 18.3787 7.43397 18.3702C6.4554 18.3616 5.48493 18.5485 4.57919 18.9197C3.67345 19.291 2.85059 19.8393 2.1586 20.5326C1.46662 21.2259 0.91938 22.0503 0.548814 22.9578C0.178247 23.8652 -0.00822528 24.8375 0.000278264 25.818C0.00878181 26.7984 0.212091 27.7673 0.598342 28.6682C0.984592 29.5691 1.54605 30.3838 2.24995 31.065L16.9898 45.8328C18.3827 47.2321 20.2621 48 22.2004 48L23.2211 47.9262C24.3507 47.7678 25.4283 47.349 26.3689 46.7026C27.3095 46.0562 28.0875 45.1998 28.6417 44.201L47.0665 10.9733C47.5369 10.1258 47.8361 9.19383 47.947 8.2305C48.0578 7.26716 47.9782 6.29139 47.7127 5.35888C47.4472 4.42637 47.0009 3.5554 46.3994 2.79569C45.7979 2.03599 45.0529 1.40243 44.207 0.931195V0.931195Z"
      fill="white"
    />
  <!-- eslint-enable max-len -->
  </svg>
</template>

<script>
export default {
  name: 'IconCheckmark',
};
</script>
