<template>
  <div
    :id="id"
    @click="onClick"
    class="relative inline-block rounded-3xl w-full cursor-pointer shadow-xl bg-white"
  >
    <div class="relative rounded-t-3xl pt-80 overflow-hidden mb-18">
      <v-lazy-image
        :src="require(`@/assets/images/${thumbnail.dir}/${thumbnail.filename}`)"
        alt=""
        height="300"
        width="300"
        class="absolute inset-0 object-cover h-full w-full"
      />

      <CardInfo
        v-if="options.info.exists"
        :text="options.info.text"
        :icon="options.info.icon"
        class="absolute top-7 left-6 z-20"
      />

      <transition name="fade">
        <CardOverlay
          v-show="isSelected"
          class="absolute inset-0 z-30 rounded-b-none"
        />
      </transition>
    </div>

    <div class="flex flex-col justify-center min-h-card absolute inset-0 top-auto z-40 bg-white rounded-3xl py-3 px-6">
      <div
        class="flex flex-wrap items-start justify-between mb-2"
        :class="{ 'mb-2': options.agent.exists || options.rating.exists || options.category.exists }"
      >
        <div>
          <span class="block font-nunito font-normal text-default text-lg text-left pr-2">
            {{ name }}
          </span>

          <span
            v-if="description"
            class="block font-rubik font-light text-xs lg:text-sm text-left text-default"
          >
            {{ description }}
          </span>
        </div>

        <span
          v-if="options.price.exists"
          class="font-rubik font-medium text-default text-lg text-right pl-2"
        >
          <span v-if="options.price.is_starting_price" class="text-xs pr-1">starting at</span>
          ${{ isPurchasableProperty ? options.buy.price.toLocaleString() : variablePrice }}
          <span v-if="options.price.is_weekly_price && !isPurchasableProperty" class="text-xs pl-0.5">per week</span>
        </span>
      </div>

      <div class="flex flex-wrap">
        <div v-if="options.agent.exists" class="w-1/2 mb-2">
          <CardAgent
            :name="options.agent.name"
            :avatar="options.agent.avatar"
            class="w-full"
          />
        </div>

        <div class="w-1/2 flex items-center justify-end">
          <CardRealEstateMeta
            v-if="options.meta.exists && cid === 0"
            :bedrooms="options.meta.items.bedrooms"
            :bathrooms="options.meta.items.bathrooms"
            :car-spaces="options.meta.items.car_spaces"
          />
        </div>

        <div
          class="flex flex-wrap items-center"
          :class="isPurchasableProperty ? 'w-2/3' : 'w-full'"
        >
          <div :class="options.meta.exists && cid === 0 && !isPurchasableProperty ? 'w-3/5 lg:w-1/2' : 'w-full'">
            <CardCategory
              v-if="options.category.exists"
              :icon="options.category.html_icon"
              :name="options.category.text"
            />

            <CardRating
              v-if="options.rating.exists"
              :rating="options.rating.value"
              :reviews="options.rating.review_amount"
              class="w-full"
            />
          </div>
        </div>

        <div
          class="flex items-center"
          :class="isPurchasableProperty ? 'w-1/3' : 'w-full'"
        >
          <div
            v-if="options.meta.exists && cid !== 0"
            :class="options.tooltip.exists ? 'w-5/6' : 'w-full'"
          >
            <CardMeta :items="options.meta.items" />
          </div>

          <div
            v-if="isPurchasableProperty || (!isProperty && options.tooltip.exists)"
            class="flex justify-end"
            :class="isPurchasableProperty ? 'w-full' : 'w-1/6'"
          >
            <IconTooltip @click.native.stop="tooltipActive = !tooltipActive" />
          </div>
        </div>

        <div
          v-show="(propertyMode === 'buy' && tooltipActive) || (!isProperty && tooltipActive)"
          class="w-full py-4 mt-4 border-t border-layout-text-default"
        >
          <p
            v-for="(tooltipData, index) in options.tooltip.data"
            :key="index"
            class="flex items-center justify-between select-none"
          >
            <span class="font-nunito font-normal text-sm">{{ tooltipData.name.replace('%s', 'Weekly') }}</span>
            <span class="font-nunito font-bold text-sm">
              {{ typeof tooltipData.value === 'number' ? `$${tooltipData.value}` : tooltipData.value }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CardInfo from '@/components/Card/CardInfo.vue';
import CardMeta from '@/components/Card/CardMeta.vue';
import CardAgent from '@/components/Card/CardAgent.vue';
import CardRating from '@/components/Card/CardRating.vue';
import CardOverlay from '@/components/Card/CardOverlay.vue';
import CardRealEstateMeta from '@/components/Card/CardRealEstateMeta.vue';
import CardCategory from '@/components/Card/CardCategory.vue';
import IconTooltip from '@/components/Icons/IconTooltip.vue';

export default {
  name: 'Card',
  components: {
    CardInfo,
    CardMeta,
    CardAgent,
    CardRating,
    CardOverlay,
    CardCategory,
    CardRealEstateMeta,
    IconTooltip,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    thumbnail: {
      type: Object,
      required: true,
      validator: (object) => !!object.dir && !!object.filename,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    qid: {
      type: String,
      required: true,
    },
    cid: {
      type: Number,
      required: true,
      validator: (num) => num === 0 || num % 1 === 0,
    },
    options: {
      type: Object,
      required: true,
      validator: (obj) => !!obj.agent
                            && !!obj.rating
                            && !!obj.info
                            && !!obj.meta
                            && !!obj.price
                            && !!obj.tooltip
                            && !!obj.category,
    },
  },
  data() {
    return {
      tooltipActive: false,
    };
  },
  computed: {
    ...mapGetters({
      currentScreen: 'screens/getCurrentScreen',
      propertyMode: 'getPropertyMode',
    }),
    isProperty() {
      return this.currentScreen === this.$screenIDs.realEstate;
    },
    isCarsForSale() {
      return this.currentScreen === this.$screenIDs.carsForSale;
    },
    isPurchasableProperty() {
      return this.isProperty && this.propertyMode === 'buy' && this.options.buy.exists && this.options.tooltip.exists;
    },
    variableName() {
      if (this.isProperty) {
        return this.isPurchasableProperty ? 'Mortgage Repayment (%s)' : 'Rent';
      }

      if (this.isCarsForSale) {
        return `${this.name} Purchase Price`;
      }

      return this.name;
    },
    variablePrice() {
      if (this.isPurchasableProperty) {
        return this.options.tooltip.data[0].value.toLocaleString();
      }

      return this.options.price.is_weekly_price
        ? this.options.price.weekly_value
        : this.options.price.value.toLocaleString();
    },
    variableData() {
      const staticPropertyData = [
        {
          name: 'Electricity',
          value: 30,
        },
        {
          name: 'Insurance',
          value: 20,
        },
      ];

      if (this.isPurchasableProperty) {
        return [...this.options.tooltip.data.slice(1, -1), ...staticPropertyData];
      }

      if (this.isProperty && !this.isPurchasableProperty) {
        return staticPropertyData;
      }

      if (this.options.tooltip.exists) {
        return this.options.tooltip.data;
      }

      return [];
    },
  },
  methods: {
    onClick() {
      let price = this.options.price.is_starting_price
        ? this.options.price.value
        : this.options.price.weekly_value;

      if (this.isCarsForSale) {
        price = this.options.price.value.toString();
      }

      if (this.isPurchasableProperty) {
        price = this.options.tooltip.data[0].value;
      }

      this.$store.commit('questions/setQuestionData', {
        name: this.variableName,
        question: this.qid,
        choice: this.id,
        value: price,
        data: this.variableData,
        categoryId: this.cid,
      });
    },
  },
};
</script>
