<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- eslint-disable max-len -->
    <path
      d="M9 1.5C4.8645 1.5 1.5 4.8645 1.5 9C1.5 13.1355 4.8645 16.5 9 16.5C13.1355 16.5 16.5 13.1355 16.5 9C16.5 4.8645 13.1355 1.5 9 1.5ZM9 15C5.69175 15 3 12.3082 3 9C3 5.69175 5.69175 3 9 3C12.3082 3 15 5.69175 15 9C15 12.3082 12.3082 15 9 15Z"
      fill="#143656"
    />
    <path
      d="M8.25 8.25H9.75V12.75H8.25V8.25ZM8.25 5.25H9.75V6.75H8.25V5.25Z"
      fill="#143656"
    />
    <!-- eslint-enable max-len -->
  </svg>
</template>

<script>
export default {
  name: 'IconTooltip',
};
</script>
