<template>
  <div class="flex flex-wrap items-center justify-start">
    <span
      v-for="(item, index) in items"
      :key="index"
      class="font-rubik font-light text-tiny lg:text-xs mr-2.5 last:mr-0"
    >
      <span class="pr-1">&#183;</span> {{ item }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'CardMeta',
  props: {
    items: {
      type: Array,
      required: true,
      validator: (array) => array.length > 0,
    },
  },
};
</script>
