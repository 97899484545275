<template>
  <div class="flex flex-wrap items-center">
    <div class="flex items-center mr-1.5">
      <div class="flex items-center" v-if="rating > 0">
        <IconStarFilled
          v-for="index in rating"
          :key="index"
          class="inline-block w-3.5 h-3.5 mx-0.5"
          :class="`text-${$store.getters['layout/getLayoutTheme']}`"
        />
      </div>

      <div class="flex items-center" v-if="rating !== 5">
        <IconStarEmpty
          v-for="index in 5 - rating"
          :key="index"
          class="inline-block w-3.5 h-3.5 mx-0.5 text-gray-400"
        />
      </div>
    </div>

    <div class="font-rubik font-light text-black opacity-25">
      {{ reviews === 1 ? `${reviews} review` : `${reviews} reviews` }}
    </div>
  </div>
</template>

<script>
import IconStarFilled from '@/components/Icons/IconStarFilled.vue';
import IconStarEmpty from '@/components/Icons/IconStarEmpty.vue';

export default {
  name: 'CardRating',
  components: {
    IconStarFilled,
    IconStarEmpty,
  },
  props: {
    rating: {
      type: Number,
      required: false,
      validator: (value) => value >= 0 && value <= 5 && value % 1 === 0,
    },
    reviews: {
      type: Number,
      required: false,
      validator: (value) => value % 1 === 0,
    },
  },
};
</script>
