<template>
  <div>
    <div class="mx-auto mb-12 lg:mb-16">
      <h2 class="font-nunito font-bold text-center text-4xl lg:text-5xl mb-8">
        {{ heading }}
      </h2>

      <p v-if="description !== ''" class="font-nunito font-medium text-center text-xl lg:text-2xl">{{ description }}</p>
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-3 gap-5 lg:gap-x-6 lg:gap-y-10">
      <component
        v-for="item in data"
        :is="component"
        :id="item.id"
        :key="item.id"
        :qid="qid"
        :cid="item.category_id"
        :is-selected="$store.getters['questions/getQuestionData'][qid].choice === item.id"

        :name="isCardComponent ? item.name : false"
        :options="isCardComponent ? item.options : false"
        :thumbnail="isCardComponent ? item.thumbnail : false"
        :description="isCardComponent ? item.description : false"

        :data="!isCardComponent ? item.data : false"
      />
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card/Card.vue';
import CardSimple from '@/components/Card/CardSimple.vue';

export default {
  name: 'ChoiceScreen',
  components: {
    Card,
    CardSimple,
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    component: {
      type: String,
      required: true,
      validator: (string) => string === 'Card' || string === 'CardSimple',
    },
    data: {
      type: Array,
      required: true,
    },
    qid: {
      type: String,
      required: true,
    },
  },
  computed: {
    isCardComponent() {
      return this.component === 'Card';
    },
  },
};
</script>
