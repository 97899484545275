<template>
  <div class="bg-black bg-opacity-60 flex items-center justify-center rounded-3xl">
    <div
      class="h-28 w-28 flex items-center justify-center rounded-full -mt-16"
      :class="`bg-${$store.getters['layout/getLayoutTheme']}`"
    >
      <IconCheckmark class="h-16 w-16" />
    </div>
  </div>
</template>

<script>
import IconCheckmark from '@/components/Icons/IconCheckmark.vue';

export default {
  name: 'CardOverlay',
  components: {
    IconCheckmark,
  },
};
</script>
