<template>
  <div id="app" class="container min-h-screen pt-8 pb-36">
    <Logo
      v-if="!isSummaryScreen && !$store.getters.getLoadingState"
      id="dynamic-logo"
      key="dynamic-logo"
    />

    <transition name="fade" mode="out-in">
      <div
        v-if="!$store.getters.getErrorState"
        v-show="!$store.getters.getLoadingState"
      >
        <WelcomeScreen
          v-if="isWelcomeScreen"
          id="welcome-screen"
          key="welcome-screen"
        />

        <PreScreenGender
          v-show="preScreenData && preScreenData.choice === null"
          id="prescreen-gender"
          key="prescreen-gender"
        />

        <ChoiceScreen
          v-for="qid in Object.keys($appData.screens)"
          v-show="currentScreen === $appData.screens[qid].screen_id && isPreScreenDataValid"
          :id="`choices-screen-${qid}`"
          :qid="qid"
          :heading="$appData.screens[qid].heading"
          :description="$appData.screens[qid].description"
          :component="$appData.screens[qid].data ? 'Card' : 'CardSimple'"
          :data="containsScreenData(qid) ? getScreenData(qid) : []"
          :key="qid"
        />

        <AdditionalCostsScreen
          v-show="isAdditionalCostsScreen"
          id="additional-costs-screen"
          key="additional-costs-screen"
          heading="Will you need any of these?"
          description=""
        />

        <Summary
          v-if="isSummaryScreen"
          id="summary-screen"
          key="summary-screen"
        />

        <Toolbar v-show="!isSummaryScreen" />
      </div>
    </transition>

    <transition name="fade" mode="in-out">
      <Loader v-if="$store.getters.getLoadingState" />
    </transition>

    <transition name="fade" mode="in-out">
      <Error v-if="$store.getters.getErrorState" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Summary from '@/components/Summary/Summary.vue';
import Toolbar from '@/components/Toolbar/Toolbar.vue';
import PreScreenGender from '@/components/PreScreenGender.vue';
import WelcomeScreen from '@/screens/WelcomeScreen.vue';
import ChoiceScreen from '@/screens/ChoiceScreen.vue';
import AdditionalCostsScreen from '@/screens/AdditionalCostsScreen.vue';
import Logo from '@/components/UI/Logo.vue';
import Loader from '@/components/UI/Loader.vue';
import Error from '@/components/UI/Error.vue';

export default {
  components: {
    Toolbar,
    PreScreenGender,
    Summary,
    Logo,
    Error,
    Loader,
    ChoiceScreen,
    WelcomeScreen,
    AdditionalCostsScreen,
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    urlParams = Object.fromEntries(urlParams.entries());

    this.$store.commit('setLoadingState', true);

    if (urlParams.uid) {
      this.$store.commit('setUserData', {
        uid: urlParams.uid,
        firstName: urlParams.firstname,
        lastName: urlParams.lastname,
      });
    } else {
      this.$store.commit('setErrorState', true);
      return;
    }

    /**
     * Listens for specific store commits and updates layout accordingly.
     */
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'layout/setBodyClass') {
        document.body.classList = mutation.payload;
      }

      if (mutation.type === 'setPropertyMode') {
        // todo; see if this could be refactored
        this.$store.commit('questions/setQuestionData', {
          ...this.$store.getters['questions/getQuestionData'].realEstate,
          ...{ question: 'realEstate' },
          ...{ choice: null },
        });
      }

      if (mutation.type === 'screens/setCurrentScreen') {
        const isFemaleLayout = this.preScreenData && this.preScreenData.choice === 'female';
        const bodyClass = isFemaleLayout ? 'bg-fashion-female' : this.screenData.bodyClass;

        this.$store.commit('layout/setLogo', this.screenData.logo);
        this.$store.commit('layout/setBodyClass', bodyClass);
        this.$store.commit('layout/setLayoutTheme', this.screenData.layout);
      }
    });
  },
  beforeMount() {
    if (this.$store.getters.getErrorState) return;

    const screenLength = Object.keys(this.$appData.screens).length + 1;
    const categoryData = this.$appData.categories.map((category) => ({
      id: category.id,
      color: category.color,
      name: category.name,
      value: 0,
      items: [],
    }));

    this.$store.commit('screens/setTotal', screenLength);
    this.$firebase.firestore()
      .collection('users')
      .doc(this.$store.getters.getUserData.uid)
      .get()
      .then((snap) => {
        const data = snap.data();

        if (data) {
          this.$store.commit('summary/setCategoryData', data.categoryData);
          this.$store.commit('screens/setCurrentScreen', this.$screenIDs.summary);

          Object.keys(data.questionData).forEach((key) => {
            this.$store.commit('questions/setQuestionData', {
              ...data.questionData[key],
              ...{ question: key },
            });
          });
        } else {
          this.$store.commit('screens/setCurrentScreen', this.$screenIDs.welcome);
          this.$store.commit('summary/setCategoryData', categoryData);
        }
      })
      .then(() => {
        this.$store.commit('setLoadingState', false);
      });
  },
  computed: {
    ...mapGetters({
      currentScreen: 'screens/getCurrentScreen',
      screenData: 'screens/getCurrentScreenData',
      preScreenData: 'screens/getPreScreenData',
      totalScreens: 'screens/getTotal',
    }),
    isWelcomeScreen() {
      return this.currentScreen === this.$screenIDs.welcome;
    },
    isAdditionalCostsScreen() {
      return this.currentScreen === this.$screenIDs.additionalCosts;
    },
    isSummaryScreen() {
      return this.currentScreen === this.$screenIDs.summary;
    },
    isPreScreenDataValid() {
      return !this.preScreenData || this.preScreenData.choice !== null;
    },
  },
  methods: {
    getScreenData(qid) {
      return this.$appData.screens[qid].data
        ? this.$appData.screens[qid].data
        : this.$appData.screens[qid][this.preScreenData.choice];
    },
    containsScreenData(qid) {
      return this.$appData.screens[qid].data || (this.preScreenData && this.preScreenData.choice);
    },
  },
};
</script>

<style lang="scss">
:root {
  /** Text colors */
  --color-text-default: #143656;
  --color-text-low-priority: #f0f0f0;
  --color-text-icon: #8A9BAB;

  /** Layout colors */
  --color-layout-default: #000000;
  --color-layout-real_estate: #be1e2d;
  --color-layout-cars_for_sale: #107fc4;
  --color-layout-groceries: #fdcb28;
  --color-layout-grub_club: #ec467b;
  --color-layout-night_life: #0695d3;
  --color-layout-holiday_hub: #25b99b;

  /** Background colors */
  --background-default: #f8fbff;
  --background-invert: #e5ebf1;
  --background-fashion-male: #EFF5FB;
  --background-fashion-female: #F4EFFB;

  /** Special */
  --special-light-blue: rgba(101, 188, 191, 0.1);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 400ms;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.v-lazy-image {
  opacity: 0.4;
  transition: filter 150ms, opacity 50ms;

  &:not(.opacity-only) {
    filter: blur(20px);
  }
}

.v-lazy-image-loaded {
  opacity: 1;

  &:not(.opacity-only) {
    filter: blur(0);
  }
}

@media (max-width: 1023px) {
  button[data-btn-back="true"] > span {
    position: relative;
    font-size: 0;
  }

  button[data-btn-back="true"] > span::before {
    content: '<';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
  }
}
</style>
