const layout = {
  namespaced: true,
  state: {
    logo: null,
    bodyClass: null,
    layoutTheme: null,
  },
  mutations: {
    setLogo(state, value) {
      state.logo = value;
    },

    setBodyClass(state, value) {
      state.bodyClass = value;
    },

    setLayoutTheme(state, value) {
      state.layoutTheme = value;
    },
  },
  getters: {
    getLayoutTheme: (state) => state.layoutTheme,
    getActiveLogo: (state) => state.logo,
  },
};

export default layout;
