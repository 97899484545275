<template functional>
  <div class="max-w-2xl lg:max-w-3xl mx-auto bg-white rounded-md px-4 py-6">
    <h1 class="font-nunito font-bold text-default text-3xl mb-6">
      Welcome {{ parent.$store.getters.getUserData.firstName }} &#x1F44B;
    </h1>

    <p class="font-nunito font-normal text-base text-default mb-6">
      Thank you for beginning your journey with the Revelations
      experience. An important part of starting out is understanding our
      relationship with money and what kind of lifestyle you would like to
      have.
    </p>

    <p class="font-nunito font-normal text-base text-default mb-6">
      This is a short interactive quiz with a number of ‘fake’ companies
      that offer services that you probably use or sign up for in real
      life.
    </p>

    <p class="font-nunito font-normal text-base text-default mb-6">
      It should only take about 5 minutes and you’ll have to make some
      tough decisions around what kind of car you would like, the holiday
      you want to save up for or the type of outfit you will purchase.
    </p>

    <p class="font-nunito font-normal text-base text-default mb-6">
      Once you’re done we’ll generate a comprehensive budget report for
      you to see your life budget.
    </p>
  </div>
</template>

<script>
export default {
  name: 'WelcomeScreen',
  functional: true,
};
</script>
