<template>
  <div class="flex flex-col justify-center mt-24">
    <p class="font-nunito font-bold text-center text-2xl lg:text-4xl mb-24 lg:mb-12">
      Please choose your gender to proceed:
    </p>

    <div class="flex items-center justify-center mt-6 lg:mt-24">
      <button
        @click="setGenderChoice('male')"
        class="flex flex-col items-center mx-4 hover:opacity-75 transition-opacity outline-none"
      >
        <img
          src="@/assets/svg/gender_male.svg"
          alt="male"
          class="w-24 h-24 lg:w-32 lg:h-32"
          height="80"
          width="80"
        >
        <span class="font-rubik text-base lg:text-lg mt-4">Male</span>
      </button>

      <button
        @click="setGenderChoice('female')"
        class="flex flex-col items-center mx-4 hover:opacity-75 transition-opacity outline-none"
      >
        <img
          src="@/assets/svg/gender_female.svg"
          alt="female"
          class="w-24 h-24 lg:w-32 lg:h-32"
          height="80"
          width="80"
        >
        <span class="font-rubik text-base lg:text-lg mt-4">Female</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreScreenGender',
  methods: {
    setGenderChoice(value) {
      const currentPreScreenData = this.$store.getters['screens/getPreScreenData'];
      this.$store.commit('screens/setPreScreenData', {
        id: currentPreScreenData.id,
        forScreen: currentPreScreenData.forScreen,
        choice: value,
      });

      // Needed to trigger the layout change
      this.$store.commit('screens/setCurrentScreen', this.$store.getters['screens/getCurrentScreen']);
    },
  },
};
</script>
