import Vue from 'vue';
import Vuex from 'vuex';
import layout from '@/store/modules/layout';
import summary from '@/store/modules/summary';
import screens from '@/store/modules/screens';
import questions from '@/store/modules/questions';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userData: {
      uid: null,
      firstName: null,
      lastName: null,
    },
    editActive: false,
    editableScreens: [],
    propertyMode: 'rent',
    isLoading: false,
    isErrorDisplayed: false,
  },
  mutations: {
    setUserData(state, payload) {
      state.userData = payload;
    },
    setEditableScreens(state, payload) {
      state.editableScreens = payload;
    },
    setEditState(state, value) {
      state.editActive = value;
    },
    setPropertyMode(state, value) {
      state.propertyMode = value;
    },
    setLoadingState(state, value) {
      state.isLoading = value;
    },
    setErrorState(state, value) {
      state.isErrorDisplayed = value;
    },
  },
  getters: {
    getUserData: (state) => state.userData,
    getEditState: (state) => state.editActive,
    getErrorState: (state) => state.isErrorDisplayed,
    getEditableScreens: (state) => state.editableScreens,
    getPropertyMode: (state) => state.propertyMode,
    getLoadingState: (state) => state.isLoading,
  },
  modules: {
    layout,
    screens,
    summary,
    questions,
  },
});
