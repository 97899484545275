<template>
  <button
    @click="onClick"
    class="block transition-opacity duration-150"
    :class="disabledClasses"
    :data-btn-back="isActionBack ? 'true' : false"
  >
    <span
      :class="[backgroundClasses, radiusClasses]"
      class="block px-1 sm:px-5 py-2.5 text-white font-rubik text-sm sm:text-base font-normal"
      tabindex="-1"
    >
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    isActionBack: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    backgroundClasses() {
      return this.isActionBack
        ? 'bg-layout-text-default h-10 w-10 lg:h-auto lg:w-auto'
        : `bg-${this.$store.getters['layout/getLayoutTheme']}`;
    },
    radiusClasses() {
      return this.isActionBack ? 'rounded-full lg:rounded-lg' : 'rounded-lg';
    },
    disabledClasses() {
      return this.isDisabled ? 'opacity-50 cursor-not-allowed' : '';
    },
  },
  methods: {
    onClick() {
      if (!this.isDisabled) {
        this.$emit('user-action:click', this.$el);
      }
    },
  },
};
</script>
