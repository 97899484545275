/** Import firebase and dependencies */
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';

/** Import vue-css-donut */
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';

import Vue from 'vue';
import { VLazyImagePlugin } from 'v-lazy-image';

import App from './App.vue';
import store from './store';

import './tailwind.css';
import './registerServiceWorker';

const DEFAULT_SCREEN_IDS = {
  welcome: 1,
  realEstate: 2,
  carsForSale: 3,
  additionalCosts: 9,
  summary: 10,
};

const firebaseConfig = {
  apiKey: 'AIzaSyBqMQiesQ4DBZOU6BcIcKNCwSqccA9hP2g',
  authDomain: 'traitstackbudget-722c3.firebaseapp.com',
  projectId: 'traitstackbudget-722c3',
  storageBucket: 'traitstackbudget-722c3.appspot.com',
  messagingSenderId: '1061244125407',
  appId: '1:1061244125407:web:f185f26a6aea57e93ba96a',
  measurementId: 'G-TS5QXP7Z76',
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.prototype.$firebase = firebase;
Vue.prototype.$screenIDs = DEFAULT_SCREEN_IDS;
Vue.prototype.$appData = require('@/data/data.json');

Vue.config.productionTip = false;

Vue.use(VLazyImagePlugin);
Vue.use(Donut);

new Vue({
  store,
  render: (h) => h(App),
}).$mount('#app');
