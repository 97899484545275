<template>
  <div class="flex justify-between items-center mb-8">
    <div class="flex flex-col">
      <div>
        <span class="font-nunito font-normal text-xl mr-2" v-html="`${icon} ${name}`"></span>
        <span class="font-nunito font-normal text-xs block sm:inline-block">{{ `($${value} per week)` }}</span>
      </div>
      <span class="font-rubik font-normal text-xs" v-if="description.length > 0">
        {{ description }}
      </span>
    </div>

    <ToggleSwitch @user-action:toggle="saveAdditionalCost($event.toggled)" />
  </div>
</template>

<script>
import ToggleSwitch from '@/components/UI/ToggleSwitch.vue';

export default {
  name: 'AdditionalCost',
  components: {
    ToggleSwitch,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    sid: {
      type: Number,
      required: true,
      validator: (num) => num % 1 === 0,
    },
    cid: {
      type: Number,
      required: true,
      validator: (num) => num % 1 === 0,
    },
  },
  methods: {
    saveAdditionalCost(isSwitchToggled) {
      const category = this.$store.getters['summary/getCategories']
        .filter((_category) => _category.id === this.cid)[0];

      const categoryItems = category.items.slice()
        .filter((_category) => _category.name !== this.name);

      if (isSwitchToggled) {
        categoryItems.push({
          screen_id: this.sid,
          name: this.name,
          data: [],
          value: this.value,
        });
      }

      this.$store.commit('summary/updateCategoryData', {
        id: category.id,
        items: categoryItems,
      });
    },
  },
};
</script>
