const summary = {
  namespaced: true,
  state: {
    total: 0,
    categories: [],
  },
  mutations: {
    setCategoryData(state, payload) {
      state.categories = payload;
    },
    updateCategoryData(state, payload) {
      const rentScreenID = 2;
      const carsForSaleScreenID = 3;
      const fashionScreenID = 8;

      state.categories = state.categories.map((c) => {
        if (c.id === payload.id) {
          let categorySubtotal = 0;

          payload.items.forEach((i) => {
            if (i.screen_id === fashionScreenID) {
              categorySubtotal = i.value * 4;
            } else if (typeof i.value === 'number') {
              categorySubtotal += i.value;
            }

            if (i.data && (i.screen_id === rentScreenID || i.screen_id === carsForSaleScreenID)) {
              i.data.forEach((b) => {
                if (typeof b.value === 'number') {
                  categorySubtotal += b.value;
                }
              });
            }
          });

          return { ...c, ...payload, ...{ value: categorySubtotal } };
        }

        return c;
      });
    },
  },
  getters: {
    getCurrentPoints: (state) => state.points,
    getTotal: (state) => {
      let total = 0;

      state.categories.forEach((c) => {
        total += c.value;
      });

      return total;
    },
    getCategories: (state) => state.categories,
  },
};

export default summary;
