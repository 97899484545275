<template>
  <div class="flex items-end justify-center my-4 px-6 border-b border-icon">
    <span
      v-for="tab in tabs"
      :key="tab.id"
      @click="setSummaryDisplay(tab)"
      :class="{'border-b-2 border-default -mb-px': currentDisplayId === tab.id}"
      class="font-nunito font-bold text-xs text-default uppercase py-4 px-2 md:px-4 lg:px-6 cursor-pointer"
    >
      {{ tab.name }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'SummaryReportTabs',
  data() {
    return {
      currentDisplayId: 4,
      tabs: [
        {
          id: 1,
          name: 'Daily',
          coefficient: 7,
        },
        {
          id: 2,
          name: 'Weekly',
          coefficient: 4.33,
        },
        {
          id: 3,
          name: 'Monthly',
          coefficient: 12,
        },
        {
          id: 4,
          name: 'Yearly',
          coefficient: 365,
        },
      ],
    };
  },
  computed: {
    displayedPriceCoefficient() {
      return this.tabs.filter((tab) => tab.id === this.currentDisplayId)[0].coefficient;
    },
  },
  methods: {
    setSummaryDisplay(tab) {
      this.currentDisplayId = tab.id;

      this.$emit('user-action:updatePriceDisplay', this.displayedPriceCoefficient);
    },
  },
};
</script>
