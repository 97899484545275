<template>
  <aside class="fixed left-0 right-0 bottom-0 bg-white rounded-b-lg rounded-t-toolbar lg:rounded-none z-50">
    <div class="container">
      <div class="grid grid-cols-5 items-center py-4 lg:py-3">
        <div class="order-first col-auto lg:col-span-1">
          <Button
            v-if="currentScreen > 2 && !editActive"
            :is-action-back="true"
            @user-action:click="changeScreen($event, -1)"
          >
            Back
          </Button>

          <ToggleSwitch
            v-show="currentScreen === 2"
            :label="{ on: 'buy', off: 'rent' }"
            @user-action:toggle="$store.commit('setPropertyMode', $event.toggled ? $event.label.on : $event.label.off)"
          />
        </div>

        <div class="order-1 lg:order-2 lg:flex lg:justify-end col-span-3 lg:col-span-1 px-6 sm:px-0">
          <Button
            v-if="currentScreenData"
            class="max-w-toolbar-btn w-full mx-auto lg:mx-0"
            @user-action:click="changeScreen($event, 1)"
            :is-disabled="currentScreen !== 1 && !currentScreenChoiceExists"
          >
            {{ currentScreenData.button_text }}
          </Button>
        </div>

        <div class="order-2 lg:order-1 lg:flex lg:justify-center col-auto lg:col-span-3">
          <ToolbarIndicator
            v-if="isIndicatorDisplayed"
            class="text-right lg:text-center"
            :currentPage="currentScreen"
            :totalPages="totalScreens"
          />
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/UI/Button.vue';
import ToggleSwitch from '@/components/UI/ToggleSwitch.vue';
import ToolbarIndicator from '@/components/Toolbar/ToolbarIndicator.vue';

export default {
  name: 'Toolbar',
  components: {
    Button,
    ToolbarIndicator,
    ToggleSwitch,
  },
  computed: {
    ...mapGetters({
      categories: 'summary/getCategories',
      totalScreens: 'screens/getTotal',
      currentScreen: 'screens/getCurrentScreen',
      currentScreenData: 'screens/getCurrentScreenData',
      questionData: 'questions/getQuestionData',
      editableScreens: 'getEditableScreens',
      editActive: 'getEditState',
    }),
    isIndicatorDisplayed() {
      return this.currentScreen && this.totalScreens && this.currentScreen <= this.totalScreens;
    },
    currentScreenQuestionData() {
      const currentScreenKey = Object.keys(this.questionData)
        .filter((key) => this.questionData[key].screenID === this.currentScreen);

      return this.questionData[currentScreenKey] ? this.questionData[currentScreenKey] : null;
    },
    currentScreenChoiceExists() {
      return this.currentScreenQuestionData ? this.currentScreenQuestionData.choice : true;
    },
  },
  methods: {
    handleEditState() {
      this.saveQuestionData();

      if (this.editableScreens.length === 0) {
        this.$store.commit('screens/setCurrentScreen', 10);
      } else if (this.editableScreens.length === 1) {
        this.$store.commit('screens/setCurrentScreen', this.editableScreens[0]);
        this.$store.commit('setEditableScreens', []);
      } else {
        this.$store.commit('screens/setCurrentScreen', this.editableScreens[0]);
        this.$store.commit('setEditableScreens', this.editableScreens.slice(0, 1));
      }
    },
    changeScreen(el, step) {
      window.scrollTo(0, 0);

      if (this.editActive) {
        this.handleEditState();
      } else {
        el.blur();
        this.saveQuestionData();
        this.$store.commit('screens/setCurrentScreen', this.currentScreen + step);
      }

      if (this.currentScreen === this.$screenIDs.summary) {
        this.$store.commit('setEditState', true);
      }
    },
    saveQuestionData() {
      if (!this.currentScreenQuestionData) return;

      const category = this.categories.filter((c) => c.id === this.currentScreenQuestionData.categoryId)[0];

      if (category) {
        const categoryItems = category.items
          .slice()
          .filter((c) => c.screen_id !== this.currentScreenQuestionData.screenID);

        categoryItems.push({
          screen_id: this.currentScreenQuestionData.screenID,
          name: this.currentScreenQuestionData.name,
          data: this.currentScreenQuestionData.data,
          value: this.currentScreenQuestionData.value,
        });

        this.$store.commit('summary/updateCategoryData', {
          id: category.id,
          name: category.name,
          items: categoryItems,
        });
      }
    },
  },
};
</script>
