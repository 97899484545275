<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
    <!-- eslint-disable max-len -->
    <path
      fill="currentColor"
      d="M5.354 6.646L5 6.293 4.293 7l.353.354.708-.708zM8 10l-.354.354.354.353.354-.353L8 10zm3.354-2.646L11.707 7 11 6.293l-.354.353.708.708zm-6.708 0l3 3 .708-.708-3-3-.708.708zm3.708 3l3-3-.708-.708-3 3 .708.708zM14.5 8A6.5 6.5 0 018 14.5v1A7.5 7.5 0 0015.5 8h-1zM8 1.5A6.5 6.5 0 0114.5 8h1A7.5 7.5 0 008 .5v1zM1.5 8A6.5 6.5 0 018 1.5v-1A7.5 7.5 0 00.5 8h1zm-1 0A7.5 7.5 0 008 15.5v-1A6.5 6.5 0 011.5 8h-1z"
    />
    <!-- eslint-enable max-len -->
  </svg>
</template>

<script>
export default {
  name: 'IconCircledArrow',
};
</script>
