<template>
  <div class="flex items-center">
    <div class="rounded-full h-5 w-5 overflow-hidden mr-1.5">
      <img
        :src="require(`@/assets/images/${avatar.dir}/${avatar.filename}`)"
        alt=""
        height="18"
        width="18"
        class="h-full w-full"
      >
    </div>

    <span class="font-rubik font-light text-default text-xs">{{ name }}</span>
  </div>
</template>

<script>
export default {
  name: 'CardAgent',
  props: {
    name: {
      type: String,
      required: true,
    },
    avatar: {
      type: Object,
      required: true,
      validator: (obj) => !!obj.dir.length && !!obj.filename.length,
    },
  },
};
</script>
